<template>
  <div class="grid grid-cols-12 h-full">
    <div
      v-if="!loading"
      class="p-6 pb-0 col-span-6 md:col-span-8 lg:col-span-8 xl:col-span-9"
    >
      <div>
        <AudioTranscriberFull
          :fileId="fileId"
          :lang="file.lang"
          :meta="file"
          @fileMarked="fileTaggedForModel"
          @save:changedLines="saveChangedLines"
          @fragment-index-changes="fragmentIndexChanged"
          @last-fragment="LastFragment"
          @finish-button-access="finishButtonAccess"
        />
      </div>
    </div>
    <header
      class="border-l col-span-6 md:col-span-4 lg:col-span-4 xl:col-span-3 bg-gray-50"
      style="height: 100vh; padding-bottom: 65px"
    >
      <div class="flex flex-col h-full">
        <div class="p-4 border-b bg-white">
          <h1 class="text-xl font-semibold">
            <div
              class="flex items-center leading-none"
              :class="{
                'text-yellow-500': file && file.status === 1,
                'text-blue-500': file && file.status === 2,
                'text-green-500': file && file.status === 3,
                'text-indigo-500': file && file.status === 4,
              }"
            >
              {{ stateTitle }}
              <i v-if="loading" class="el-icon-loading"></i>
            </div>
          </h1>
          <div class="mt-1 text-xs font-light text-gray-700">
            ID: {{ fileId }}
          </div>
        </div>
        <div class="p-4 h-full overflow-y-auto">
          <ul class="flex flex-col" v-if="file && file.comments">
            <li
              v-for="comment in file.comments"
              :key="comment.datetime"
              class="mb-3 font-light text-xs text-gray-600 bg-white border rounded-lg p-2 px-3 inline-block w-4/5"
              :class="{
                'self-end rounded-tr-none':
                  user && user._id === comment.author._id,
                'rounded-tl-none': user && user._id !== comment.author._id,
              }"
            >
              <div style="font-size: 13px" class="font-semibold mb-0.5">
                {{ comment.author.first_name }} {{ comment.author.last_name }}
              </div>
              <div class="mb-0.5 flex items-center">
                <span
                  :class="{
                    'text-yellow-500': comment.status.before === 1,
                    'text-blue-500': comment.status.before === 2,
                    'text-green-500': comment.status.before === 3,
                    'text-indigo-500': comment.status.before === 4,
                  }"
                  >{{ getStatusTitle(comment.status.before) }}</span
                >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="12px"
                    viewBox="0 0 24 24"
                    fill="black"
                  >
                    <path
                      d="M4 12H20M20 12L14 6M20 12L14 18"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <span
                  :class="{
                    'text-yellow-500': comment.status.after === 1,
                    'text-blue-500': comment.status.after === 2,
                    'text-green-500': comment.status.after === 3,
                    'text-indigo-500': comment.status.after === 4,
                  }"
                  >{{ getStatusTitle(comment.status.after) }}</span
                >
              </div>
              <div>{{ comment.comment }}</div>
              <div style="font-size: 11px" class="text-gray-400 text-right">
                {{ dateFormat(comment.datetime, "dd MMM HH:mm") }}
              </div>
            </li>
          </ul>
        </div>
        <div class="mt-auto">
          <!--          <ul class="text-xs mb-1.5 font-light px-2">-->
          <!--            <li-->
          <!--              v-for="t in tips"-->
          <!--              :key="t"-->
          <!--              @click="comment = t"-->
          <!--              class="border inline-block mr-1 rounded px-1.5 py-0.5 text-gray-500 cursor-pointer hover:bg-gray-50 bg-white"-->
          <!--            >-->
          <!--              {{ t }}-->
          <!--            </li>-->
          <!--          </ul>-->
          <div class="bg-gray-50 border-t pt-2">
            <textarea
              v-model="comment"
              type="textarea"
              placeholder="Примечание"
              name=""
              class="w-full p-2.5 pt-1 font-light text-xs"
              cols="30"
              rows="2"
            ></textarea>
            <div
              class="flex items-center justify-end mb-4 pr-2 py-2.5 border-b border-t border-solid border-gray-200"
            >
              <el-button
                v-if="userScopes.includes('annotator') && state === '0'"
                type="danger"
                plain
                @click="rejectFile"
                >Отказ</el-button
              >
              <el-button
                v-for="(button, index) in availableButtons"
                :key="index"
                @click="changeStatus(button.status.to)"
                :type="button.type"
                plain
                :disabled="changeStatusLoading"
              >
                {{ button.title }}
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </header>
    <el-dialog v-model="showRatingDialog" title="Оцените качество">
      <el-rate :size="80" v-model="rating"></el-rate>
    </el-dialog>
  </div>
</template>

<script>
import { changeStatus, getFile, rejectFile } from "@/api/files";
import { getStatus } from "./shared/statuses";
import { format } from "date-fns";
import AudioTranscriberFull from "@/views/files/shared/components/AudioTranscriberFull.vue";

export default {
  components: {
    AudioTranscriberFull,
  },
  props: {
    state: {
      type: [String],
      required: true,
    },
    fileId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      cue: 0,
      loading: true,
      changeStatusLoading: false,
      showRatingDialog: false,
      rating: null,
      fragmentIndex: 1,
      fullLines: [],
      controlButtons: [
        {
          title: "В работу",
          status: {
            from: 0,
            to: 1,
          },
          scopes: ["verifier", "annotator"],
          type: "primary",
        },
        {
          title: "Завершить",
          sign: "annotator_end",
          status: {
            from: 1,
            to: 2,
          },
          scopes: ["annotator"],
          type: "primary",
        },
        {
          title: "На верификацию",
          status: {
            from: 2,
            to: 3,
          },
          scopes: ["verifier"],
          type: "primary",
        },
        {
          title: "Завершить",
          sign: "verifier_end",
          status: {
            from: 3,
            to: 4,
          },
          scopes: ["verifier", "admin"],
          type: "primary",
        },
        {
          title: "Вернуть на верификацию",
          status: {
            from: 4,
            to: 3,
          },
          scopes: ["verifier", "admin"],
          type: "primary",
        },
        {
          title: "Возврат",
          status: {
            from: 3,
            to: 1,
          },
          scopes: ["verifier"],
          type: "danger",
        },
        {
          title: "Возврат",
          status: {
            from: 1,
            to: 0,
          },
          scopes: ["annotator"],
          type: "danger",
        },
        {
          title: "Возврат в работу",
          status: {
            from: 2,
            to: 1,
          },
          scopes: ["annotator", "verifier"],
          type: "danger",
        },
      ],
      canFinish: false,
      activeTab: "operator",

      file: {},
      comment: null,
      // tips: ["Не прошел верификацию", "Исправлено"],
      rejectFileComment: null,
    };
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    userScopes() {
      return this.$store.getters["user/getUserScopes"];
    },
    stateTitle() {
      if (!this.file) return null;
      return this.getStatusTitle(this.file.status);
    },
    availableButtons() {
      if (!this.file) return [];
      return this.controlButtons.filter((cb) => {
        // Check for the "Размечен" button
        if (
          cb.sign === "annotator_end" &&
          cb.status.from === parseInt(this.file.status)
        ) {
          // Add any additional conditions for the "Размечен" button here
          if (this.userScopes.includes("annotator")) {
            // Check if fragmentIndex is the last index of fullLines
            return (
              this.fragmentIndex === this.fullLines.length && !this.canFinish
            );
          }
        }
        if (
          cb.sign === "verifier_end" &&
          cb.status.from === parseInt(this.file.status)
        ) {
          if (
            this.userScopes.includes("verifier") ||
            this.userScopes.includes("admin")
          ) {
            // Check if fragmentIndex is the last index of fullLines
            return (
              this.fragmentIndex === this.fullLines.length && !this.canFinish
            );
          }
        }

        // For all other buttons, use the existing logic
        if (cb.status.from === parseInt(this.file.status)) {
          const filteredArray = cb.scopes.filter((value) =>
            this.userScopes.includes(value)
          );
          if (filteredArray.length > 0) {
            return true;
          }
        }
        return false;
      });
    },
  },
  async mounted() {
    this.file = await getFile(this.fileId);
    this.loading = false;

    console.log("tempVariable", this.$options.tempVariable);
  },
  methods: {
    finishButtonAccess(access) {
      this.canFinish = access;
    },
    fileTaggedForModel(taggedFileForModel) {
      console.log(this.file);
      this.file.is_model = taggedFileForModel;
    },
    async changeStatus(status) {
      this.changeStatusLoading = true;
      const res = await changeStatus(this.fileId, status, this.comment);
      this.changeStatusLoading = false;
      if (res._success) {
        this.$router.push({
          name: "file-view-full-audio",
          params: { state: status, fileId: this.fileId },
        });
        this.file = await getFile(this.fileId);
      } else {
        this.$message.error(res.message || "Ошибка");
      }
      console.log("change", status);
    },
    async rejectFile() {
      try {
        const { value } = await this.$msgbox.prompt(
          "Введите комментарий.",
          "Отказ файла",
          {
            confirmButtonText: "Отправить",
            cancelButtonText: "Отменить",
            inputPattern: /^.*\S.*$/, // Ensures the comment is not empty or whitespace
            inputErrorMessage: "Комментарий не может быть пустым.",
          }
        );

        const trimmedComment = value.trim();

        const res = await rejectFile(this.fileId, 6, trimmedComment, 1);
        if (res._success) {
          this.$router.push({
            name: "files-list",
            params: { state: 0 },
          });
        }
      } catch (err) {
        if (err === "cancel") {
          this.$message.info("Отказ файла отменено.");
        } else {
          console.error(err);
        }
      }
    },
    getStatusTitle(status) {
      return getStatus(status) && getStatus(status).title;
    },
    dateFormat(date, frmt) {
      return format(new Date(date), frmt);
    },
    saveChangedLines({ payload }) {
      this.lines = payload;
    },
    fragmentIndexChanged(fragment_index) {
      this.fragmentIndex = fragment_index;
    },
    LastFragment(lines) {
      this.fullLines = lines;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-rate:deep(.el-rate__item) {
  .el-rate-icon {
    font-size: 50px !important;
  }
}
</style>
