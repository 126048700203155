<template>
  <div class="flex items-start flex-col w-full">
    <div class="flex flex-wrap w-full items-center gap-2.5" v-if="state">
      <div>
        <el-tooltip
          content="Пропустить (Alt + V)"
          :show-after="300"
          placement="top"
        >
          <el-checkbox border v-model="form.skip">Пропустить</el-checkbox>
        </el-tooltip>
      </div>
      <el-tooltip
        content="Сохранить (Alt + S)"
        placement="top"
        :show-after="300"
        :disabled="notAllowed || textSkipAccess"
      >
        <el-button
          :disabled="notAllowed || textSkipAccess"
          type="primary"
          plain
          @click="saveChat"
          >Сохранить</el-button
        ></el-tooltip
      >
    </div>
    <div class="w-full mt-2">
      <el-input
        ref="transcribe-input"
        id="transcribe-input"
        class="mt-2 h-16"
        style="font-size: 16px"
        type="text"
        :disabled="form.skip || viewMode"
        v-model="form.text"
        @input="filterInput"
        @keydown="handleKeydown"
      />
      <div
        v-if="invalidWords && invalidWords.length > 0"
        class="text-red-500 mt-1 break-words"
        style="font-size: 16px"
      >
        {{ invalidWords }}<span class="ml-0.5">не найдены в словаре.</span>
      </div>
      <span
        v-if="notAllowed && !viewMode"
        class="text-red-500"
        style="font-size: 16px"
      >
        Недопустимые символы</span
      >
      <span
        v-else-if="textSkipAccess && !viewMode"
        class="text-xs text-red-500"
      >
        Введите текст или выберите Пропустить
      </span>
    </div>
  </div>
</template>
<script>
import apiClient from "@/api/api-client";
import store from "@/store";

export default {
  name: "FillingFormFullAudio",
  props: {
    lang: {
      type: String,
      default: "kz",
    },
    lines: {
      type: Array,
    },
    lineIndex: {
      type: Number,
    },
    state: {
      type: Boolean,
    },
    viewMode: {
      type: Boolean,
    },
    verifierCheckWords: {
      type: Boolean,
    },
  },
  data() {
    return {
      form: {
        text: "",
        skip: false,
      },
      invalidWords: [],
      debounce: null,
      isButtonDisabled: true,
      editingLineIndex: null,
    };
  },
  computed: {
    notAllowed() {
      if (this.form.text === "") {
        return false;
      }
      const allowedSymbolsRegex =
        /^[АӘБВГҒДЕЁЖЗИЙКҚЛМНҢОӨПРСТУҮҰФХҺЦЧШЩЪЫІЬЭЮЯаәбвгғдеёжзийкқлмнңоөпрстуүұфхһцчшщъыіьэюя\s\-.*?+]+$/u;
      const isNotAllowed = !allowedSymbolsRegex.test(this.form.text);

      // Emit to parent if the value of notAllowed changes
      this.$emit("not-allowed-changed", isNotAllowed);

      return isNotAllowed;
    },
    textSkipAccess() {
      const access = this.form.text.trim() === "" && this.form.skip === false;
      this.$emit("fragment-access-changed", access);
      return access;
    },
  },
  watch: {
    lines: {
      immediate: true,
      handler(newLines) {
        if (newLines && newLines.length > 0) {
          this.form.text = newLines[0].text || "";
          this.form.skip = newLines[0].skip || false;
        }
      },
    },
    lineIndex() {
      this.invalidWords = [];
      if (this.verifierCheckWords) {
        this.validateAndSave();
      }
    },
  },
  mounted() {
    this.focusInput();
    if (this.lines && this.lines.length > 0 && this.lines[0]) {
      this.form.text = this.lines[0].text || ""; // Safely set form.text to the first text
    }
    if (this.lines && this.lines.length > 0 && this.lines[0]) {
      this.form.skip = this.lines[0].skip || false; // Safely set form.text to the first text
    }
    window.addEventListener("keydown", this.handleSave);
    window.addEventListener("keydown", this.handleSkip);
    if (!this.notAllowed && !this.textSkipAccess && this.state) {
      this.saveInterval = setInterval(() => {
        if (!this.notAllowed && !this.textSkipAccess && this.state) {
          store.dispatch("notify/add", {
            type: "info",
            message: "Автосохранение",
            size: "mini",
          });
        }
        this.saveChat();
      }, 30000);
    }
  },
  beforeUnmount() {
    if (this.saveInterval) {
      clearInterval(this.saveInterval);
    }
    window.removeEventListener("keydown", this.handleSave);
    window.removeEventListener("keydown", this.handleSkip);
  },
  methods: {
    getCurrentInput() {
      return {
        text: this.form.text || "",
        skip: this.form.skip || false,
        index: this.lineIndex,
      };
    },
    focusInput() {
      this.$refs["transcribe-input"].focus();
    },
    filterInput() {
      // Define your validation regex (Cyrillic letters, spaces, and allowed symbols)
      const validationSymbols =
        /^[АӘБВГҒДЕЁЖЗИЙКҚЛМНҢОӨПРСТУҮҰФХҺЦЧШЩЪЫІЬЭЮЯаәбвгғдеёжзийкқлмнңоөпрстуүұфхһцчшщъыіьэюя\s\-.*?+]*$/u;

      // Filter out invalid characters
      this.form.text = this.form.text
        .split("") // Split the text into characters
        .filter((char) => validationSymbols.test(char)) // Keep only allowed characters
        .join(""); // Join the characters back into a string
    },
    async validateAndSave() {
      this.invalidWords = [];
      const text = this.form.text;
      try {
        const response = await apiClient.get(`/word`, {
          params: { texts: text },
        });
        // Add to invalid words list if word is not valid
        if (response && response.data && !response.data._success) {
          this.invalidWords = response.data.err_words || [];
        }
      } catch (error) {
        console.error(error);
        this.invalidWords = [];
      }
    },
    insertFormData({ text, skip }) {
      this.form = { text, skip };
    },
    saveChat() {
      if (!this.notAllowed && !this.textSkipAccess) {
        this.$emit("save:chat", this.form.text, this.form.skip, this.lineIndex);
      }
      this.validateAndSave();
    },
    handleKeydown(event) {
      if (
        !event.shiftKey &&
        event.keyCode === 13 &&
        !this.notAllowed &&
        !this.textSkipAccess
      ) {
        event.preventDefault();
        this.saveChat();
      }
    },
    handleSave(event) {
      // Check if both Control and S keys are pressed
      if ((event.altKey || event.metaKey) && event.code === "KeyS") {
        event.preventDefault();
        this.saveChat();
      }
    },
    handleSkip(event) {
      // Check if Alt and V keys are pressed
      if ((event.altKey || event.metaKey) && event.code === "KeyV") {
        event.preventDefault();
        this.form.skip = !this.form.skip;
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
